/* reset styling (prevent conflicts with bootstrap, materialize.css, etc.) */

div.jsoneditor .jsoneditor-search input {
  height: auto;
  border: inherit;
}

div.jsoneditor .jsoneditor-search input:focus {
  border: none !important;
  box-shadow: none !important;
}

div.jsoneditor table {
  border-collapse: collapse;
  width: auto;
}

div.jsoneditor td,
div.jsoneditor th {
  padding: 0;
  display: table-cell;
  text-align: left;
  vertical-align: inherit;
  border-radius: inherit;
}


div.jsoneditor-field,
div.jsoneditor-value,
div.jsoneditor-readonly {
  border: 1px solid transparent;
  min-height: 16px;
  min-width: 32px;
  padding: 2px;
  margin: 1px;
  word-wrap: break-word;
  float: left;
}

/* adjust margin of p elements inside editable divs, needed for Opera, IE */

div.jsoneditor-field p,
div.jsoneditor-value p {
  margin: 0;
}

div.jsoneditor-value {
  word-break: break-word;
}

div.jsoneditor-readonly {
  min-width: 16px;
  color: #808080;
}

div.jsoneditor-empty {
  border-color: #d3d3d3;
  border-style: dashed;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-empty::after,
div.jsoneditor-value.jsoneditor-empty::after {
  pointer-events: none;
  color: #d3d3d3;
  font-size: 8pt;
}

div.jsoneditor-field.jsoneditor-empty::after {
  content: "field";
}

div.jsoneditor-value.jsoneditor-empty::after {
  content: "value";
}

div.jsoneditor-value.jsoneditor-url,
a.jsoneditor-value.jsoneditor-url {
  color: green;
  text-decoration: underline;
}

a.jsoneditor-value.jsoneditor-url {
  display: inline-block;
  padding: 2px;
  margin: 2px;
}

a.jsoneditor-value.jsoneditor-url:hover,
a.jsoneditor-value.jsoneditor-url:focus {
  color: #ee422e;
}

div.jsoneditor td.jsoneditor-separator {
  padding: 3px 0;
  vertical-align: top;
  color: #808080;
}

div.jsoneditor-field[contenteditable=true]:focus,
div.jsoneditor-field[contenteditable=true]:hover,
div.jsoneditor-value[contenteditable=true]:focus,
div.jsoneditor-value[contenteditable=true]:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
  background-color: #FFFFAB;
  border: 1px solid yellow;
  border-radius: 2px;
}

div.jsoneditor-field.jsoneditor-highlight-active,
div.jsoneditor-field.jsoneditor-highlight-active:focus,
div.jsoneditor-field.jsoneditor-highlight-active:hover,
div.jsoneditor-value.jsoneditor-highlight-active,
div.jsoneditor-value.jsoneditor-highlight-active:focus,
div.jsoneditor-value.jsoneditor-highlight-active:hover {
  background-color: #ffee00;
  border: 1px solid #ffc700;
  border-radius: 2px;
}

div.jsoneditor-value.jsoneditor-string {
  color: #008000;
}

div.jsoneditor-value.jsoneditor-object,
div.jsoneditor-value.jsoneditor-array {
  min-width: 16px;
  color: #808080;
}

div.jsoneditor-value.jsoneditor-number {
  color: #ee422e;
}

div.jsoneditor-value.jsoneditor-boolean {
  color: #ff8c00;
}

div.jsoneditor-value.jsoneditor-null {
  color: #004ED0;
}

div.jsoneditor-value.jsoneditor-invalid {
  color: #000000;
}

div.jsoneditor-tree button {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: transparent url("img/jsoneditor-icons.svg");
}

div.jsoneditor-mode-view tr.jsoneditor-expandable td.jsoneditor-tree,
div.jsoneditor-mode-form tr.jsoneditor-expandable td.jsoneditor-tree {
  cursor: pointer;
}

div.jsoneditor-tree button.jsoneditor-collapsed {
  background-position: 0 -48px;
}

div.jsoneditor-tree button.jsoneditor-expanded {
  background-position: 0 -72px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu {
  background-position: -48px -72px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu:hover,
div.jsoneditor-tree button.jsoneditor-contextmenu:focus,
div.jsoneditor-tree button.jsoneditor-contextmenu.jsoneditor-selected,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu {
  background-position: -48px -48px;
}

div.jsoneditor-tree *:focus {
  outline: none;
}

div.jsoneditor-tree button:focus {
  /* TODO: nice outline for buttons with focus
  outline: #97B0F8 solid 2px;
  box-shadow: 0 0 8px #97B0F8;
  */
  background-color: #f5f5f5;
  outline: #e5e5e5 solid 1px;
}

div.jsoneditor-tree button.jsoneditor-invisible {
  visibility: hidden;
  background: none;
}

div.jsoneditor-tree div.jsoneditor-show-more {
  display: inline-block;
  padding: 3px 4px;
  margin: 2px 0;
  background-color: #e5e5e5;
  border-radius: 3px;
  color: #808080;
  font-family: arial, sans-serif;
  font-size: 10pt;
}

div.jsoneditor-tree div.jsoneditor-show-more a {
  display: inline-block;
  color: #808080;
}

div.jsoneditor-tree div.jsoneditor-show-more a:hover,
div.jsoneditor-tree div.jsoneditor-show-more a:focus {
  color: #ee422e;
}

div.jsoneditor {
  color: #1A1A1A;
  border: 1px solid #3883fa;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  line-height: 100%;
}

div.jsoneditor-tree table.jsoneditor-tree {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
}

div.jsoneditor-outer {
  position: static;
  width: 100%;
  height: 100%;
  margin: -35px 0 0 0;
  padding: 35px 0 0 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div.jsoneditor-outer.has-nav-bar {
  margin: -61px 0 0 0;
  padding: 61px 0 0 0;
}

div.jsoneditor-outer.has-status-bar {
  margin: -35px 0 -26px 0;
  padding: 35px 0 26px 0;
}

textarea.jsoneditor-text,
.ace-jsoneditor {
  min-height: 150px;
}

div.jsoneditor-tree {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

textarea.jsoneditor-text {
  width: 100%;
  height: 100%;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline-width: 0;
  border: none;
  background-color: white;
  resize: none;
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
  background-color: #d3d3d3;
}

tr.jsoneditor-selected button.jsoneditor-dragarea,
tr.jsoneditor-selected button.jsoneditor-contextmenu {
  visibility: hidden;
}

tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu {
  visibility: visible;
}

div.jsoneditor-tree button.jsoneditor-dragarea {
  background: url("img/jsoneditor-icons.svg") -72px -72px;
  cursor: move;
}

div.jsoneditor-tree button.jsoneditor-dragarea:hover,
div.jsoneditor-tree button.jsoneditor-dragarea:focus,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
  background-position: -72px -48px;
}

div.jsoneditor tr,
div.jsoneditor th,
div.jsoneditor td {
  padding: 0;
  margin: 0;
}

div.jsoneditor td {
  vertical-align: top;
}

div.jsoneditor td.jsoneditor-tree {
  vertical-align: top;
}

div.jsoneditor-field,
div.jsoneditor-value,
div.jsoneditor td,
div.jsoneditor th,
div.jsoneditor textarea,
.jsoneditor-schema-error {
  font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
  font-size: 10pt;
  color: #1A1A1A;
}

/* popover */

.jsoneditor-schema-error {
  cursor: default;
  display: inline-block;
  /*font-family: arial, sans-serif;*/
  height: 24px;
  line-height: 24px;
  position: relative;
  text-align: center;
  width: 24px;
}

div.jsoneditor-tree .jsoneditor-schema-error {
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  background: url("img/jsoneditor-icons.svg")  -168px -48px;
}

.jsoneditor-schema-error .jsoneditor-popover {
  background-color: #4c4c4c;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  color: #fff;
  display: none;
  padding: 7px 10px;
  position: absolute;
  width: 200px;
  z-index: 4;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-above {
  bottom: 32px;
  left: -98px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-below {
  top: 32px;
  left: -98px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-left {
  top: -7px;
  right: 32px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-right {
  top: -7px;
  left: 32px;
}

.jsoneditor-schema-error .jsoneditor-popover:before {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  content: '';
  display: block;
  left: 50%;
  margin-left: -7px;
  position: absolute;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-above:before {
  border-top: 7px solid #4c4c4c;
  bottom: -7px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-below:before {
  border-bottom: 7px solid #4c4c4c;
  top: -7px;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-left:before {
  border-left: 7px solid #4c4c4c;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: '';
  top: 19px;
  right: -14px;
  left: inherit;
  margin-left: inherit;
  margin-top: -7px;
  position: absolute;
}

.jsoneditor-schema-error .jsoneditor-popover.jsoneditor-right:before {
  border-right: 7px solid #4c4c4c;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  content: '';
  top: 19px;
  left: -14px;
  margin-left: inherit;
  margin-top: -7px;
  position: absolute;
}

.jsoneditor-schema-error:hover .jsoneditor-popover,
.jsoneditor-schema-error:focus .jsoneditor-popover {
  display: block;
  -webkit-animation: fade-in .3s linear 1, move-up .3s linear 1;
  -moz-animation: fade-in .3s linear 1, move-up .3s linear 1;
  -ms-animation: fade-in .3s linear 1, move-up .3s linear 1;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-ms-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*@-webkit-keyframes move-up {*/

/*from   { bottom: 24px; }*/

/*to { bottom: 32px; }*/

/*}*/

/*@-moz-keyframes move-up {*/

/*from   { bottom: 24px; }*/

/*to { bottom: 32px; }*/

/*}*/

/*@-ms-keyframes move-up {*/

/*from   { bottom: 24px; }*/

/*to { bottom: 32px; }*/

/*}*/

/* JSON schema errors displayed at the bottom of the editor in mode text and code */

.jsoneditor .jsoneditor-text-errors {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffef8b;
  border-top: 1px solid #ffd700;
}

.jsoneditor .jsoneditor-text-errors td {
  padding: 3px 6px;
  vertical-align: middle;
}

.jsoneditor-text-errors .jsoneditor-schema-error {
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  background: url("img/jsoneditor-icons.svg")  -168px -48px;
}
/* ContextMenu - main menu */

div.jsoneditor-contextmenu-root {
  position: relative;
  width: 0;
  height: 0;
}

div.jsoneditor-contextmenu {
  position: absolute;
  box-sizing: content-box;
  z-index: 99999;
}

div.jsoneditor-contextmenu ul,
div.jsoneditor-contextmenu li {
  box-sizing: content-box;
  position: relative;
}

div.jsoneditor-contextmenu ul {
  position: relative;
  left: 0;
  top: 0;
  width: 128px;
  background: white;
  border: 1px solid #d3d3d3;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3);
  list-style: none;
  margin: 0;
  padding: 0;
}

div.jsoneditor-contextmenu ul li button {
  position: relative;
  padding: 0 4px 0 0;
  margin: 0;
  width: 128px;
  height: auto;
  border: none;
  cursor: pointer;
  color: #4d4d4d;
  background: transparent;
  font-size: 10pt;
  font-family: arial, sans-serif;
  box-sizing: border-box;
  text-align: left;
}

/* Fix button padding in firefox */

div.jsoneditor-contextmenu ul li button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

div.jsoneditor-contextmenu ul li button:hover,
div.jsoneditor-contextmenu ul li button:focus {
  color: #1a1a1a;
  background-color: #f5f5f5;
  outline: none;
}

div.jsoneditor-contextmenu ul li button.jsoneditor-default {
  width: 96px;
  /* 128px - 32px */
}

div.jsoneditor-contextmenu ul li button.jsoneditor-expand {
  float: right;
  width: 32px;
  height: 24px;
  border-left: 1px solid #e5e5e5;
}

div.jsoneditor-contextmenu div.jsoneditor-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
  margin: 0;
  background-image: url("img/jsoneditor-icons.svg");
}

div.jsoneditor-contextmenu ul li ul div.jsoneditor-icon {
  margin-left: 24px;
}

div.jsoneditor-contextmenu div.jsoneditor-text {
  padding: 4px 0 4px 24px;
  word-wrap: break-word;
}

div.jsoneditor-contextmenu div.jsoneditor-text.jsoneditor-right-margin {
  padding-right: 24px;
}

div.jsoneditor-contextmenu ul li button div.jsoneditor-expand {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 4px 0 0;
  background: url("img/jsoneditor-icons.svg") 0 -72px;
  opacity: 0.4;
}

div.jsoneditor-contextmenu ul li button:hover div.jsoneditor-expand,
div.jsoneditor-contextmenu ul li button:focus div.jsoneditor-expand,
div.jsoneditor-contextmenu ul li.jsoneditor-selected div.jsoneditor-expand,
div.jsoneditor-contextmenu ul li button.jsoneditor-expand:hover div.jsoneditor-expand,
div.jsoneditor-contextmenu ul li button.jsoneditor-expand:focus div.jsoneditor-expand {
  opacity: 1;
}

div.jsoneditor-contextmenu div.jsoneditor-separator {
  height: 0;
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
  margin-top: 5px;
}

div.jsoneditor-contextmenu button.jsoneditor-remove > div.jsoneditor-icon {
  background-position: -24px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-remove:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-remove:focus > div.jsoneditor-icon {
  background-position: -24px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-append > div.jsoneditor-icon {
  background-position: 0 -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-append:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-append:focus > div.jsoneditor-icon {
  background-position: 0 0;
}

div.jsoneditor-contextmenu button.jsoneditor-insert > div.jsoneditor-icon {
  background-position: 0 -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-insert:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-insert:focus > div.jsoneditor-icon {
  background-position: 0 0;
}

div.jsoneditor-contextmenu button.jsoneditor-duplicate > div.jsoneditor-icon {
  background-position: -48px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-duplicate:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-duplicate:focus > div.jsoneditor-icon {
  background-position: -48px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-sort-asc > div.jsoneditor-icon {
  background-position: -168px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-sort-asc:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-sort-asc:focus > div.jsoneditor-icon {
  background-position: -168px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-sort-desc > div.jsoneditor-icon {
  background-position: -192px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-sort-desc:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-sort-desc:focus > div.jsoneditor-icon {
  background-position: -192px 0;
}

/* ContextMenu - sub menu */

div.jsoneditor-contextmenu ul li button.jsoneditor-selected,
div.jsoneditor-contextmenu ul li button.jsoneditor-selected:hover,
div.jsoneditor-contextmenu ul li button.jsoneditor-selected:focus {
  color: white;
  background-color: #ee422e;
}

div.jsoneditor-contextmenu ul li {
  overflow: hidden;
}

div.jsoneditor-contextmenu ul li ul {
  display: none;
  position: relative;
  left: -10px;
  top: 0;
  border: none;
  box-shadow: inset 0 0 10px rgba(128, 128, 128, 0.5);
  padding: 0 10px;
  /* TODO: transition is not supported on IE8-9 */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}



div.jsoneditor-contextmenu ul li ul li button {
  padding-left: 24px;
  animation: all ease-in-out 1s;
}

div.jsoneditor-contextmenu ul li ul li button:hover,
div.jsoneditor-contextmenu ul li ul li button:focus {
  background-color: #f5f5f5;
}

div.jsoneditor-contextmenu button.jsoneditor-type-string > div.jsoneditor-icon {
  background-position: -144px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-type-string:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-string:focus > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-string.jsoneditor-selected > div.jsoneditor-icon {
  background-position: -144px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-auto > div.jsoneditor-icon {
  background-position: -120px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-type-auto:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-auto:focus > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-auto.jsoneditor-selected > div.jsoneditor-icon {
  background-position: -120px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-object > div.jsoneditor-icon {
  background-position: -72px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-type-object:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-object:focus > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-object.jsoneditor-selected > div.jsoneditor-icon {
  background-position: -72px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-array > div.jsoneditor-icon {
  background-position: -96px -24px;
}

div.jsoneditor-contextmenu button.jsoneditor-type-array:hover > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-array:focus > div.jsoneditor-icon,
div.jsoneditor-contextmenu button.jsoneditor-type-array.jsoneditor-selected > div.jsoneditor-icon {
  background-position: -96px 0;
}

div.jsoneditor-contextmenu button.jsoneditor-type-modes > div.jsoneditor-icon {
  background-image: none;
  width: 6px;
}

/* pico modal styling */

.jsoneditor-modal-overlay {
  position: absolute !important;
  background: rgb(1,1,1) !important;
  opacity: 0.3 !important;
}

.jsoneditor-modal {
  position: absolute !important;
  max-width: 100% !important;
  border-radius: 2px !important;
  padding: 45px 15px 15px 15px !important;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3) !important;
}

.jsoneditor-modal .pico-modal-header {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  font-family: arial, sans-serif;
  font-size: 11pt;
  background: #3883fa;
  color: white;
}

.jsoneditor-modal table td {
  padding: 5px 0;
  padding-right: 20px;
  text-align: left;
  vertical-align: middle;
  font-size: 10pt;
  font-family: arial, sans-serif;
  color: #4d4d4d;
}



.jsoneditor-modal table td.jsoneditor-modal-input {
  text-align: right;
  padding-right: 0;
  white-space: nowrap;
}

.jsoneditor-modal table td.jsoneditor-modal-actions {
  padding-top: 15px;
}

.jsoneditor-modal .pico-close {
  background: none !important;
  font-size: 24px !important;
  top: 7px !important;
  right: 7px !important;
  color: white;
}

.jsoneditor-modal select,
.jsoneditor-modal input {
  background: #f5f5f5;
  border: 1px solid #d3d3d3;
  color: #4d4d4d;
  border-radius: 3px;
  cursor: pointer;
}

.jsoneditor-modal .jsoneditor-select-wrapper {
  position: relative;
}

.jsoneditor-modal .jsoneditor-select-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #666;
  position: absolute;
  right: 8px;
  top: 10px;
  pointer-events: none;
}

.jsoneditor-modal select {
  padding: 3px 24px 3px 10px;
  width: 100%;
  max-width: 300px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0;
  text-overflow: "";
  font-size: inherit;
  line-height: inherit;
}

.jsoneditor-modal select::-ms-expand {
  display: none;
}

.jsoneditor-modal .jsoneditor-button-group input {
  padding: 4px 10px;
  margin: 0;
  border-radius: 0;
  border-left-style: none;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-first {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left-style: solid;
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-last {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-asc input.jsoneditor-button-asc,
.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-desc input.jsoneditor-button-desc {
  background: #3883fa;
  border-color: #3883fa;
  color: white;
}

.jsoneditor-modal input {
  padding: 4px 20px;
}
div.jsoneditor-menu {
  width: 100%;
  height: 35px;
  padding: 2px;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: white;
  background-color: #3883fa;
  border-bottom: 1px solid #3883fa;
}

div.jsoneditor-menu > button,
div.jsoneditor-menu > div.jsoneditor-modes > button {
  width: 26px;
  height: 26px;
  margin: 2px;
  padding: 0;
  border-radius: 2px;
  border: 1px solid transparent;
  background: transparent url("img/jsoneditor-icons.svg");
  color: white;
  opacity: 0.8;
  font-family: arial, sans-serif;
  font-size: 10pt;
  float: left;
}

div.jsoneditor-menu > button:hover,
div.jsoneditor-menu > div.jsoneditor-modes > button:hover {
  background-color: rgba(255,255,255,0.2);
  border: 1px solid rgba(255,255,255,0.4);
}

div.jsoneditor-menu > button:focus,
div.jsoneditor-menu > button:active,
div.jsoneditor-menu > div.jsoneditor-modes > button:focus,
div.jsoneditor-menu > div.jsoneditor-modes > button:active {
  background-color: rgba(255,255,255,0.3);
}

div.jsoneditor-menu > button:disabled,
div.jsoneditor-menu > div.jsoneditor-modes > button:disabled {
  opacity: 0.5;
}

div.jsoneditor-menu > button.jsoneditor-collapse-all {
  background-position: 0 -96px;
}

div.jsoneditor-menu > button.jsoneditor-expand-all {
  background-position: 0 -120px;
}

div.jsoneditor-menu > button.jsoneditor-undo {
  background-position: -24px -96px;
}

div.jsoneditor-menu > button.jsoneditor-undo:disabled {
  background-position: -24px -120px;
}

div.jsoneditor-menu > button.jsoneditor-redo {
  background-position: -48px -96px;
}

div.jsoneditor-menu > button.jsoneditor-redo:disabled {
  background-position: -48px -120px;
}

div.jsoneditor-menu > button.jsoneditor-compact {
  background-position: -72px -96px;
}

div.jsoneditor-menu > button.jsoneditor-format {
  background-position: -72px -120px;
}

div.jsoneditor-menu > button.jsoneditor-repair {
  background-position: -96px -96px;
}

div.jsoneditor-menu > div.jsoneditor-modes {
  display: inline-block;
  float: left;
}

div.jsoneditor-menu > div.jsoneditor-modes > button {
  background-image: none;
  width: auto;
  padding-left: 6px;
  padding-right: 6px;
}

div.jsoneditor-menu > button.jsoneditor-separator,
div.jsoneditor-menu > div.jsoneditor-modes > button.jsoneditor-separator {
  margin-left: 10px;
}

div.jsoneditor-menu a {
  font-family: arial, sans-serif;
  font-size: 10pt;
  color: white;
  opacity: 0.8;
  vertical-align: middle;
}

div.jsoneditor-menu a:hover {
  opacity: 1;
}

div.jsoneditor-menu a.jsoneditor-poweredBy {
  font-size: 8pt;
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
}
table.jsoneditor-search input,
table.jsoneditor-search div.jsoneditor-results {
  font-family: arial, sans-serif;
  font-size: 10pt;
  color: #1A1A1A;
  background: transparent;
  /* For Firefox */
}

table.jsoneditor-search div.jsoneditor-results {
  color: white;
  padding-right: 5px;
  line-height: 24px;
}

table.jsoneditor-search {
  position: absolute;
  right: 4px;
  top: 4px;
  border-collapse: collapse;
  border-spacing: 0;
}

table.jsoneditor-search div.jsoneditor-frame {
  border: 1px solid transparent;
  background-color: white;
  padding: 0 2px;
  margin: 0;
}

table.jsoneditor-search div.jsoneditor-frame table {
  border-collapse: collapse;
}

table.jsoneditor-search input {
  width: 120px;
  border: none;
  outline: none;
  margin: 1px;
  line-height: 20px;
}

table.jsoneditor-search button {
  width: 16px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  background: url("img/jsoneditor-icons.svg");
  vertical-align: top;
}

table.jsoneditor-search button:hover {
  background-color: transparent;
}

table.jsoneditor-search button.jsoneditor-refresh {
  width: 18px;
  background-position: -99px -73px;
}

table.jsoneditor-search button.jsoneditor-next {
  cursor: pointer;
  background-position: -124px -73px;
}

table.jsoneditor-search button.jsoneditor-next:hover {
  background-position: -124px -49px;
}

table.jsoneditor-search button.jsoneditor-previous {
  cursor: pointer;
  background-position: -148px -73px;
  margin-right: 2px;
}

table.jsoneditor-search button.jsoneditor-previous:hover {
  background-position: -148px -49px;
}
div.jsoneditor div.autocomplete.dropdown {
  position: absolute;
  background: white;
  box-shadow: 2px 2px 12px rgba(128, 128, 128, 0.3);
  border: 1px solid #d3d3d3;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: default;
  margin: 0;
  padding-left: 2pt;
  padding-right: 5pt;
  text-align: left;
  outline: 0;
  font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
  font-size: 10pt;
}

div.jsoneditor div.autocomplete.dropdown .item {
  color: #333;
}

div.jsoneditor div.autocomplete.dropdown .item.hover {
  background-color: #ddd;
}

div.jsoneditor div.autocomplete.hint {
  color: #aaa;
  top: 4px;
  left: 4px;
}
div.jsoneditor-treepath {
  padding: 0 5px;
  overflow: hidden;
}

div.jsoneditor-treepath div.jsoneditor-contextmenu-root {
  position: absolute;
  left: 0;
}

div.jsoneditor-treepath span.jsoneditor-treepath-element {
  margin: 1px;
  font-family: arial, sans-serif;
  font-size: 10pt;
}

div.jsoneditor-treepath span.jsoneditor-treepath-seperator {
  margin: 2px;
  font-size: 9pt;
  font-family: arial, sans-serif;
}

div.jsoneditor-treepath span.jsoneditor-treepath-element:hover,
div.jsoneditor-treepath span.jsoneditor-treepath-seperator:hover {
  cursor: pointer;
  text-decoration: underline;
}
div.jsoneditor-statusbar {
  line-height: 26px;
  height: 26px;
  margin-top: -1px;
  color: #808080;
  background-color: #ebebeb;
  border-top: 1px solid #d3d3d3;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 10pt;
}

div.jsoneditor-statusbar > .jsoneditor-curserinfo-label {
  margin: 0 2px 0 4px;
}

div.jsoneditor-statusbar > .jsoneditor-curserinfo-val {
  margin-right: 12px;
}

div.jsoneditor-statusbar > .jsoneditor-curserinfo-count {
  margin-left: 4px;
}
div.jsoneditor-navigation-bar {
  width: 100%;
  height: 26px;
  line-height: 26px;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #d3d3d3;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #808080;
  background-color: #ebebeb;
  overflow: hidden;
  font-family: arial, sans-serif;
  font-size: 10pt;
}